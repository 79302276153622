import "./third_party/vicowa-web-components/dist/vicowa-translate/vicowa-translate.js";
import "./third_party/vicowa-web-components/dist/vicowa-button/vicowa-button.js";
import "./third_party/vicowa-web-components/dist/vicowa-sidebar/vicowa-sidebar.js";
import "./third_party/vicowa-web-components/dist/vicowa-content-container/vicowa-content-container.js";
import "./third_party/vicowa-web-components/dist/vicowa-image-container/vicowa-image-container.js";
import "./webcomponents/wedding-mandyrodney-heart.js";
import translator from "./third_party/vicowa-web-components/dist/utilities/translate.js";
import features from "./third_party/vicowa-web-components/dist/utilities/featureDetect.js";
import { getRouter } from "/third_party/vicowa-web-components/dist/utilities/route.js";
import { createQuickAccess } from "/third_party/web-component-base-class/dist/tools.js";

const router = getRouter(window);
const controls = createQuickAccess(document, "id");
if (features.webp) {
	document.body.classList.add("webp");
}

const settings = document.cookie.split(";").map((p_Item) => p_Item.trim().split("=")).reduce((p_Previous, p_CurrentPair) => { p_Previous[p_CurrentPair[0]] = p_CurrentPair[1]; return p_Previous; }, {});
const mediaChange = window.matchMedia("(max-width: 600px)");
const sideBar = document.querySelector("vicowa-sidebar");
setTimeout(() => {
	const checkMedia = (p_Result) => {
		sideBar.expanded = !p_Result.matches;
	};
	checkMedia(mediaChange);
	mediaChange.addListener(checkMedia);
}, 1000);

document.querySelector("#toggle-expand").addEventListener("click", () => {
	sideBar.expanded = !sideBar.expanded;
});

Array.from(document.querySelectorAll(".nav-button")).forEach((p_Element) => {
	p_Element.onclick = (event) => {
		router.goTo(p_Element.href, { title: p_Element.dataset.title });
		event.preventDefault();
	};
});

translator.addTranslationUpdatedObserver((p_Translator) => {
	if (p_Translator.language) {
		document.body.setAttribute("lang", p_Translator.language);
		document.cookie = `lang=${p_Translator.language};max-age=31536000`;
	}
}, null);
document.body.setAttribute("lang", settings.lang || document.body.getAttribute("lang"));
translator.language = document.body.getAttribute("lang");

Array.from(document.querySelectorAll(".language-select")).forEach((p_Element) => {
	p_Element.addEventListener("click", () => {
		translator.language = p_Element.getAttribute("id");
	});
});

router.addRoute("/:file(.*)", (context) => {
	const parts = context.params.file.split("#");
	controls.content.location = `./${parts[0] || "mandyrodney-home"}.js`;
	if (context.customData) {
		document.title = `Mandy and Rodney's ${context.customData.title}`;
	}
});
